@font-face {
  font-family: "Bebas-Bold";
  src: url(./fonts/BebasNeueBold.otf) format("truetype");
}

@font-face {
  font-family: "Bebas-Light";
  src: url(./fonts/BebasNeueLight.otf) format("truetype");
}

@font-face {
  font-family: "Bebas-Neue-Regular";
  src: url(./fonts/BebasNeueRegular.otf) format("truetype");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url(./fonts/Roboto-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Light";
  src: url(./fonts/Roboto-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Futura-Light";
  src: url(./fonts/FUTURA45LIGHT.TTF) format("truetype");
}
@font-face {
  font-family: "Futura-Light-Italic";
  src: url(./fonts/FUTURA46LIGHTITALIC.TTF) format("truetype");
}
@font-face {
  font-family: "Futura-Regular";
  src: url(./fonts/FUTURA55REGULAR.TTF) format("truetype");
}
@font-face {
  font-family: "Futura-Italic";
  src: url(./fonts/FUTURA56ITALIC.TTF) format("truetype");
}
@font-face {
  font-family: "Futura-Medium";
  src: url(./fonts/FUTURA65MEDIUM.TTF) format("truetype");
}
@font-face {
  font-family: "Futura-Medium-Italic";
  src: url(./fonts/FUTURA66MEDIUMITALIC.TTF) format("truetype");
}
@font-face {
  font-family: "Futura-Bold";
  src: url(./fonts/FUTURA75BOLD.TTF) format("truetype");
}
@font-face {
  font-family: "Futura-Bold-Italic";
  src: url(./fonts/FUTURA76BOLDITALIC.TTF) format("truetype");
}

* {
  margin: 0;
  padding: 0;
}

html {
  color: white;
  font-size: 15px;
}

body {
  color: #ffffff;
  font-family: "Hind Siliguri", sans-serif;
  background: #142c39;
  overflow-x: hidden;
}

.MuiInputBase-input {
  color: cyan;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #4c7789; */
  background: #142c39;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #13262c; */
  background: #116473;
  border-radius: 10px;
  opacity: 0.6;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #2a4d53; */
  background: #00ffff;
}

*:focus {
  outline: none;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #ffffff !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #ffffff !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ffffff !important;
}

.MuiFormControlLabel-label {
  font-family: Hind Siliguri !important;
}

.MuiFormLabel-root {
  font-family: Hind Siliguri !important;
}

.background-login {
  overflow: hidden;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background: url(../src/assets/img/backgroundLogin.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.highcharts-drillup-button .highcharts-button-box + text {
  fill: red !important;
}

.background-main.background-dashboard {
  background-size: cover;
  width: 100vw;
  height: 100vh;
  font-family: Hind Siliguri;
  text-transform: uppercase;
  background-color: #163647;
  background-repeat: round;
  /* background-repeat: repeat; */
}
.rec.rec-arrow {
  border-radius: 50%;
  color: #00ffff;
  font-size: 0.9rem;
  position: relative;
  margin-left: -35px;
  margin-right: -35px;
  z-index: 1;
  background-color: #142c39;
}
.jtKTCe {
  display: none !important;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
  color: #fff;
  background-color: #142c39 !important;
}
.rec.rec-arrow:hover:enabled {
  color: #fff;
  background-color: #142c39 !important;
}
.rec.rec-arrow:focus:enabled {
  color: #fff;
  background-color: #142c39 !important;
}

.rec-carousel-item:focus {
  outline: none;
}

.rec-carousel-item:focus:enabled {
  outline: none;
  background-color: #142c39 !important;
}

.background-main {
  /* padding: 15px; */
  height: 100vh;
  width: 100vh;
  color: #ffffff;
  background-size: cover;
  font-family: Hind Siliguri;
  /* text-transform: uppercase; */
  background-repeat: no-repeat;
  background-color: #142c39;
  overflow: hidden;
}

.background-main.none-padding {
  padding: 0;
}

.highcharts-credits {
  display: none;
}

.MuiTooltip-tooltip {
  transform: scale(1.6) !important;
}

.background-main .side-bar-container .side-bar-container-head,
.background-main .side-bar-container .side-bar-container-footer {
  display: flex;
  overflow: hidden;
  position: relative;
}

.background-main .side-bar-container .side-bar-container-body {
  text-align: center;
}

[class*="materialui-daterange-picker-makeStyles-filled"] {
  background-color: #061618 !important;
}

[class*="materialui-daterange-picker-makeStyles-outlined"] {
  border: 1px solid #061618 !important;
}

[class*="materialui-daterange-picker-makeStyles-highlighted"] {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

[class*="materialui-daterange-picker-MuiInput-underline"]:after {
  border-bottom: rgba(0, 0, 0, 0.3) !important;
}

.background-main
  .side-bar-container
  .side-bar-container-body
  .side-bar-logo
  .img-logo {
  max-width: 50%;
}

.background-main .side-bar-container .side-bar-container-footer {
  transform: rotate(180deg);
}

.section-container .section-container-body {
  /* padding-left: 1rem;
    padding-top: 1rem; */
  padding: 1rem;
}

.section-container .section-container-body .section-body {
  min-height: 70px;
  overflow-y: auto;
  overflow-x: hidden;
  /* max-height: calc(90vh - 5.5rem); */
  max-height: calc(82vh - 5.5rem);
}

.section-body .dash-container {
  margin: 0;
}

.section-container .section-container-body .container-breadcrum {
  display: flex;
  cursor: pointer;
  margin: 0 0 1rem;
}

.section-container
  .section-container-body
  .container-breadcrum
  .breadcrum-item {
  font-size: 1.2rem;
  margin-right: 2px;
  padding: 0.1em 1.5rem 0;
  background: #1e4249;
}

.section-container
  .section-container-body
  .container-breadcrum
  .breadcrum-item.active {
  background: #0a1a24;
}

.dash-container {
  flex-direction: column;
  margin-top: -1rem !important;
}

.row-container.dash .MuiGrid-root {
  max-width: calc(25% - 4.5rem);
}

.row-container.dash .MuiGrid-root:first-child {
  margin-left: 4px !important;
}

.row-container.dash .MuiGrid-root:last-child {
  margin-right: 4px !important;
}

.dash-container {
  flex-direction: column;
  margin-top: -1rem !important;
}

.row-container.dash .MuiGrid-root {
  max-width: calc(25% - 4.5rem);
}

.side-bar-container .side-bar-container-body .side-bar-title {
  padding: 15px;
}

.pointer {
  cursor: pointer;
}

@media only screen and (min-width: 1920px) {
  .background-main.background-dashboard {
    background-size: contain;
  }
}

@media only screen and (max-width: 480px) {
  .MuiGrid-root.background-main.background-dashboard
    .MuiGrid-root.side-bar-container {
    display: none;
  }

  .MuiGrid-root.section-container.MuiGrid-item {
    width: 100%;
  }

  .section-container-head div:first-child {
    min-width: 100%;
  }
}
