.cardContainer {
  display: grid;
  grid-template-rows: 1fr 5fr 2fr;
  gap: 10px;
  width: auto;
  background: linear-gradient(
    rgba(121, 121, 121, 0.5),
    rgba(139, 139, 139, 0.404)
  );
  min-height: 300px;
  height: min-content;
  width: 550px;
  margin: 2rem;
  box-sizing: border-box;
  aspect-ratio: 5 / 6;
  color: #00ffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

.cardContainer header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardContainer h2 {
  font-size: 200px;
  line-height: 0.9;
  color: rgb(23, 170, 23);
  font-weight: bold;
}
.cardContainer h3 {
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.cardContainer h4 {
  font-size: 25px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: justify;
}
.cardContainer h5 {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: justify;
}

.headerImage {
  width: 70px;
  height: auto;
}

.cardMainContent {
  height: fit-content;
  background: linear-gradient(rgba(14, 50, 59, 0.5), rgba(17, 73, 126, 0.404));
  width: 100%;
}

.playerInfo {
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  position: relative;
}

.playerImage {
  width: 300px;
  height: 350px;
  aspect-ratio: 2/3;
  margin-left: -10%;
  background-color: transparent;
  position: absolute;
  z-index: 2;
}

.playerImageWithBlur {
  width: 300px;
  height: 350px;
  aspect-ratio: 2/3;
  margin-left: -6%;
  background-color: transparent;
  position: absolute;
  z-index: 1;
  filter: blur(10px);
}

.statisticsBox {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  border: white;
}
.statisticsBoxValue {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  height: 20px;
  margin-bottom: 0.2rem;
}
.statisticsBoxLabel {
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  text-align: center;
  height: 20px;
}
.statisticsBoxContainer {
  padding: 0.2rem;
  margin-bottom: 0.5rem;
  border-radius: 10px;
  color: #00ffff;
}
